import React from "react";
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";
import Spacer from "../components/Spacer/spacer";
import Container from "../components/Container/container";
import SkillsShowcase from "../components/SkillsShowcase/skillsShowcase";

const about = () => {
  return (
    <>
      <Header />
      <div className="flex flex-col w-full min-h-screen">
        <p className="w-full text-white text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-center pb-10 pt-10">
          About Me
        </p>
        <div className="flex flex-row justify-center w-full h-auto bg-white pb-5 pt-5">
          <p className="flex w-4/5 2xl:w-1/2 text-white text-sm md:text-lg lg:text-xl xl:text-2xl 2xl:text-2xl text-center">
            My primary focus lies in the realm of low-level programming,
            predominantly in C. I find studying the interaction of hardware and
            software, and striving for efficient performance, to be deeply
            fulfilling. My pursuit of technical excellence has led me to embrace
            challenges of varying disciplines and complexities.
          </p>
        </div>
        <Spacer />
        <div className="flex flex-row justify-center w-full h-auto bg-white pb-5 pt-5">
          <p className="flex w-4/5 2xl:w-1/2 text-white text-sm md:text-lg lg:text-xl xl:text-2xl 2xl:text-2xl text-center">
            I'm driven by a relentless pursuit of expertise in all that I do.
            The challenges of programming and game development invigorate me,
            and I am excited to continue pushing the boundaries of my knowledge.
            If you're as passionate about interactive experiences as I am, I
            would be delighted to connect and collaborate on future endeavors.
          </p>
        </div>
        <Spacer />
        <p className="w-full text-white text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-center pb-10 pt-10">
          Skills
        </p>
        <SkillsShowcase />
        <Spacer />
      </div>
      <Footer />
    </>
  );
};

export default about;
