import React, { useState } from "react";
import { useEffect } from "react";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (formSubmitted) {
      const timer = setTimeout(() => {
        setFormSubmitted(false);
      }, 3000); // Set the duration (in milliseconds) for the pop-up message to disappear (e.g., 3000ms = 3 seconds)

      return () => {
        clearTimeout(timer); // Clear the timer if the component unmounts before the timeout
      };
    }
  }, [formSubmitted]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://2rkfegljll.execute-api.us-east-1.amazonaws.com/dev",
        {
          method: "POST",
          mode: "no-cors",
          body: JSON.stringify(formData),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // Handle success (e.g., show a success message)
        console.log("Form submitted successfully");
      } else {
        // Handle errors (e.g., show an error message)
        console.log("Form submitted");
        setFormSubmitted(true); // Set formSubmitted to true
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="flex flex-col overflow-x-hidden	 items-center w-full h-auto">
      <p className="text-2xl md:text-5xl text-white pb-5 md:pb-10">
        Contact Me
      </p>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col w-full items-center gap-5"
      >
        <label className="flex flex-col w-2/3 2xl:w-1/2 items-start gap-2 text-xl md:text-3xl text-white">
          Name:
          <input
            className="w-full h-8 md:h-10 xl:h-15 rounded-md text-xs md:text-base xl:text-2xl text-center bg-white"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </label>
        <label className="flex flex-col w-2/3 2xl:w-1/2 gap-2 text-xl md:text-3xl text-white">
          Email:
          <input
            className="w-full h-8 md:h-10 xl:h-15 rounded-md text-xs md:text-base xl:text-2xl text-center bg-white"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </label>
        <label className="flex flex-col w-2/3 2xl:w-1/2 gap-2 text-xl md:text-3xl text-white">
          Message:
          <textarea
            className="w-full h-24 md:h-36 xl:h-64 rounded-md text-xs md:text-base xl:text-2xl text-center bg-white"
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </label>
        <button
          className="flex justify-center w-20 rounded-md pt-2 pb-2 text-center text-white bg-white"
          type="submit"
        >
          Submit
        </button>
        {formSubmitted && <p className="text-white">Form has been submitted</p>}
      </form>
    </div>
  );
}

export default ContactForm;
