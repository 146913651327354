import React from "react";

const header = () => {
  return (
    <div className="flex flex-row flex-nowrap gap-5 sm:gap-20 justify-center items-center w-screen h-20 sm:h-28 lg:h-32 bg-white">
      <a
        className="flex h-fit text-lg sm:text-2xl md:text-3xl lg:text-5xl text-white hover:underline"
        href="/"
      >
        Home
      </a>
      <a
        className="flex h-fit text-lg sm:text-2xl md:text-3xl lg:text-5xl text-white hover:underline"
        href="/about"
      >
        About
      </a>
      <a
        className="flex h-fit text-lg sm:text-2xl md:text-3xl lg:text-5xl text-white hover:underline"
        href="/projects"
      >
        Projects
      </a>
      <a
        className="flex h-fit text-lg sm:text-2xl md:text-3xl lg:text-5xl text-white hover:underline"
        href="/contact"
      >
        Contact
      </a>
    </div>
  );
};

export default header;
