import React from "react";
import Header from "../components/Header/header";
import App from "../App";
import Footer from "../components/Footer/footer";

export default function Root() {
  return (
    <div className="flex flex-col justify-between min-w-screen min-h-screen bg-white">
      <Header></Header>
      <div className="flex flex-col justify-between min-w-screen min-h-screen bg-white">
        <App />
      </div>
      <Footer />
    </div>
  );
}
