import React from "react";

const MainContent = () => {
  return (
    <div className="flex w-full justify-center bg-cyan-400">
      <div></div>
    </div>
  );
};

export default MainContent;