import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

export default function footer() {
  return (
    <>
      <div className="flex flex-row flex-auto w-full h-20 sm:h-28 lg:h-32 justify-end items-center bg-white">
        <div className="flex flex-col place-content-start w-1/2 min-h-full">
          <p className="h-10 sm:h-14 text-center text-xl sm:text-3xl text-white pt-1 sm:pt-3">
            Links
          </p>
          <div className="flex flex-row justify-center gap-5 h-10 sm:h-14 pt-1 sm:pt-3">
            <a href="https://github.com/Naityve">
              <FontAwesomeIcon
                icon={faGithub}
                className="text-xl sm:text-3xl text-white"
              />
            </a>
            <a href="https://twitter.com/naityve">
              <FontAwesomeIcon
                icon={faTwitter}
                className="text-xl sm:text-3xl text-white"
              />
            </a>
            <a href="https://www.youtube.com/@naityve6016/featured">
              <FontAwesomeIcon
                icon={faYoutube}
                className="text-xl sm:text-3xl text-white"
              />
            </a>
          </div>
        </div>
        <div className="flex flex-col place-content-start w-1/2 min-h-full">
          <p className="h-10 sm:h-14 text-center text-xl sm:text-3xl text-white pt-1 sm:pt-3">
            Contact
          </p>
          <div className="flex flex-row justify-center h-10 sm:h-14 w-full ">
            <p className="flex flex-nowrap h-full text-center text-white text-xs sm:text-xl pt-1 sm:pt-3">
              Email: naityve@gmail.com
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
